
import React, { Component } from 'react';
import './App.css';
import VisitorForm from './components/VisitorForm'

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
        <div id="_body">
          <VisitorForm />
        </div>
    );
  }
}
export default App;