import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { UxdLayout, WoodsidePrimaryVertical } from '@uxd/react';

ReactDOM.render(<UxdLayout>
      <AppBar color="primary" position="static">
            <Toolbar>
            <WoodsidePrimaryVertical style={{paddingRight: 24}} width={80} height={80} />
                <Typography style={{padding: 10, fontSize: '16px'}} variant="title" color="inherit">
                COVID-19 Declaration Form
                </Typography>
            </Toolbar>
        </AppBar>
        <App /></UxdLayout>, document.getElementById('root'));
