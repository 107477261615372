
import React, { Component } from 'react';
import { Loader, Form, Input, Dimmer, Segment, Button, Dropdown, Grid } from 'semantic-ui-react'
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2'
// import Axios from 'axios';

class VisitorForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //Form Fields
      recaptcha: '',
      submitter: '',
      site: '',
      siteVisit: '',
      wopId: '',
      siteId: '',
      team: '',
      employer: '',
      email: '',
      phone: '',
      internationalTravel:'',
      //Drop Downs
      teamList: [
        { key: 'Red', text: 'Red', value: 'Red' },
        { key: 'Blue', text: 'Blue', value: 'Blue' },
        { key: 'Gold', text: 'Gold', value: 'Gold' },
        { key: 'N/A', text: 'N/A', value: 'N/A' }],
      siteList: [
        { key: 'Perth (All Locations)', text: 'Perth (All Locations)', value: 'Perth (All Locations)' },
        { key: 'Pluto (& Pluto Alpha)', text: 'Pluto (& Pluto Alpha)', value: 'Pluto (& Pluto Alpha)' },
        { key: 'KGP Residential', text: 'KGP Residential', value: 'KGP Residential' },
        { key: 'KLE', text: 'KLE', value: 'KLE' },
        { key: 'KSF (KBSF & BMF)', text: 'KSF (KBSF & BMF)', value: 'KSF (KBSF & BMF)' },
        { key: 'KGP FIFO', text: 'KGP FIFO', value: 'KGP FIFO' },
        { key: 'Offshore (NRC, GWA, Angel, Okha)', text: 'Offshore (NRC, GWA, Angel, Okha)', value: 'Offshore (NRC, GWA, Angel, Okha)' },
        { key: 'Ngujima-Yin', text: 'Ngujima-Yin', value: 'Ngujima-Yin' },
        { key: 'Ocean Apex', text: 'Ocean Apex', value: 'Ocean Apex' },
        { key: 'DPS-1', text: 'DPS-1', value: 'DPS-1' },
        { key: 'International & other Australian offices', text: 'International & other Australian offices', value: 'International & other Australian offices' },
      ],
      //Display Error Message
      nameError: false,
      siteError: false,
      siteVisitError: false,
      wopIdError: false,
      siteIdError: false,
      teamError: false,
      phoneError: false,
      emailError: false,
      mobilisationError: false,
      closeContactWithDiagnosed: false,
      closeContactFluSymptomsError: false,
      symptomsExperiencedError: false,
      testedAwaitingResultsError: false,
      closeContactWithTestedAwaitingResultsError: false,
      roleError: false,
      supervisorError: false,
      employerError: false,
      countriesTravelled_NotHighRiskError: false,
      countriesTravelled_HighRiskError: false,
      //Other
      queryString: '',
      ffw: false, //Declaration accepted
    };

    this.loader = 0;
    this.rejectVisitor = false
  }

  componentDidMount() {
    const qs = window.location.search
    const urlParams = new URLSearchParams(qs).get("token");
    this.setState({ queryString: urlParams })
  }

  resetInputFields = () => {
    this.setState({
      recaptcha: '',
      empType: '',
      submitter: '',
      siteVisit: '',
      wopId: '',
      siteId: '',
      team: '',
      email: '',
      phone: '',
      internationalTravel:'',
      closeContactWithDiagnosed: '',
      role: '',
      mobilisation: '',
      site: '',
      employer: '',
      countriesTravelled_HighRisk: [],
      closeContactFluSymptoms: '',
      symptomsExperienced: '',
      testedAwaitingResults: '',
      closeContactWithTestedAwaitingResults: '',
      countriesTravelled_NotHighRisk: [],
    })
  }

  resetErrorState = () => {
    this.setState({
      nameError: false,
      siteVisitError: false,
      siteError: false,
      wopIdError: false,
      siteIdError: false,
      teamError: false,
      employerError: false,
      emailError: false,
      phoneError: false,
      internationalTravelError: false,
    })
  }

  setLoader = (add) => {
    this.loader = add ? this.loader + 1 : this.loader - 1;
    this.setState({ loader: this.loader });
  }

  handleChange = (e, data, name, isDate) => {
    let newState = {}
    if (isDate) {
      newState[name] = e.target.value;
      this.setState({ reRender: true }, () => {
        this.setState({ reRender: false });
      })
    } else {
      newState[name] = data.value;
    }
    this.setState(newState)
  }

  // handleEmpTypeChange = (e, { value }) => {
  //   this.setState({
  //     empType: value,
  //   })
  //   if (value === 'v') {
  //     this.setState({
  //       visitorForm: false,
  //       woodsideLinks: true
  //     })
  //   }
  //   else {
  //     this.setState({
  //       woodsideLinks: false,
  //       visitorForm: true
  //     })
  //   }
  // }

  validateEmail = (email) => {
    let re = /\S+@\S+\.\S+/
    return re.test(String(email).toLowerCase());
  }

  validateSiteAccess = () => {
    if (this.state.internationalTravel === 'Yes'
    ) {
      return false //Access denied
    }
    else {
      return true //Access approved
    }
  }

  onChange = (value) => {
    this.setState({
      recaptcha: value
    })
  }

  submit = async () => {
    this.setLoader('add');
    const {
      //Form
      submitter,
      site,
      siteVisit,
      wopId,
      siteId,
      team,
      employer,
      email,
      phone, 
      internationalTravel,
      ffw,
      //Other
      recaptcha
    } = this.state

    await this.resetErrorState();
    let fieldErrorList = []

    //Check email is valid
    if (!email || !this.validateEmail(email)) {
      fieldErrorList.push('Invalid Email')
      this.setState({ emailError: true })
    }
    //Check mandatory fields
    if (!recaptcha) {
      fieldErrorList.push('reCAPTCHA')
    }
    if (!submitter) {
      fieldErrorList.push('Name')
      this.setState({ nameError: true })
    }
    if (!site) {
      fieldErrorList.push('Site')
      this.setState({ siteError: true })
    }
    if (!siteVisit && site !== 'Perth (All Locations)') {
      fieldErrorList.push('Site Visit this week')
      this.setState({ siteVisitError: true })
    }
    if (!wopId && site === 'Perth (All Locations)') {
      fieldErrorList.push('WOP ID')
      this.setState({ wopIdError: true })
    }
    if (!siteId && site !== 'Perth (All Locations)') {
      fieldErrorList.push('Site ID')
      this.setState({ siteIdError: true })
    }
    if (!team) {
      fieldErrorList.push('Team')
      this.setState({ teamError: true })
    }
    if (!employer) {
      fieldErrorList.push('Employer')
      this.setState({ employerError: true })
    }
    if (!email) {
      fieldErrorList.push('Email')
      this.setState({ emailError: true })
    }
    if (!phone) {
      fieldErrorList.push('Phone')
      this.setState({ phoneError: true })
    }
    if (!internationalTravel) {
      fieldErrorList.push('International Travel')
      this.setState({ internationalTravelError: true })
    }
    if (!ffw) {
      fieldErrorList.push('Declaration Checkbox')
    }
    if (fieldErrorList.length > 0) {
      let a = fieldErrorList.join(', ').toString();
      this.setLoader();
      Swal.fire({
        title: 'Incomplete Fields',
        text: 'Incomplete Fields!',
        icon: 'error',
        confirmButtonText: 'Cancel',
        html:
          `${a} `
      })
      return;
    }
    if (ffw) {
      const body = JSON.stringify({
        form: {
          submitDate: new Date(),
          submitter: submitter,
          site: site,
          siteVisit: siteVisit,
          submitterid: wopId === null ? siteId : wopId,
          team: team,
          employer: employer,
          email: email,
          phone: phone,
          internationalTravel: internationalTravel,
          ffw: ffw,
        },
        other: {
          recaptcha: recaptcha
        }
      })
      console.log(body)
      try {
        // const res = await Axios.post(process.env.REACT_APP_APIGATEWAY_URL,
        //   body,
        //   { headers: { "Authorization": `${queryString}` } });
        // console.log('res', res)
        const res = {message: ""}

        let accessApproved = await this.validateSiteAccess();

        if (accessApproved) {
          Swal.fire(
            'Site Access Permitted',
            `${res.message}`,
            'success'
          )
        }
        else {
          Swal.fire(
            'Site Access Denied',
            `${res.message}`,
            'success'
          )
        }
        this.resetErrorState();
        this.resetInputFields();
        this.setLoader();
      }
      catch (error) {
        this.setLoader();
        console.log('e', error)
        alert(error);
      }
    }
    else {
      alert('Declaration not accepted, please accept to submit this form.')
    }
  }

  render() {
    const {
      //Error Status
      nameError,
      siteError,
      siteVisitError,
      wopIdError,
      siteIdError,
      teamError,
      phoneError,
      emailError,
      employerError,
      internationalTravelError,
      //Input Fields
      submitter,
      siteVisit,
      wopId,
      siteId,
      team,
      email,
      phone,
      internationalTravel,
      employer,
      ffw,
      site,
      //Dropdowns
      siteList,
      teamList } = this.state

    return (
      <div>
        <p> Woodside is implementing measures to minimise the exposure of personnel to the COVID-19 virus and ensure business continuity is maintained.   To confirm your clearance to access Woodside assets and premises, please complete the following declaration. If you are not able to declare as fit for work, or in the event of a change to guidance from “authority”, this information may be used to contact you. The information will not be used to update any other Woodside records.
Access will be automatically suspended unless the following information is received.</p>
        <div className="main">
          {/* <Grid centered>
            <Form>
              <Form.Group inline>
                <Form.Radio label='Visitor' value='v' checked={empType === 'v'} onChange={this.handleEmpTypeChange} />
                <Form.Radio label='Woodside Employee' value='w' checked={empType === 'w'} onChange={this.handleEmpTypeChange} />
              </Form.Group>
            </Form>
          </Grid>
          <br /> */}
          <div>
            <Segment>
              <Grid centered>
                <Form autoComplete="off" className="visitorForm" onSubmit={this.submit} >
                  <div>
                      <Form.Field error={nameError}>
                        <label className="required">Full Name (e.g. John Doe)</label>
                        <Input value={submitter} size='small' type="text" onChange={(e, data) => this.handleChange(e, data, 'submitter', false)} name="submitter" placeholder='Name' />
                      </Form.Field>
                      <Form.Field error={siteError}>
                        <label className="required">What is the main Woodside site or workplace you will be accessing?</label>
                        <Dropdown value={site} size='small' onChange={(e, data) => this.handleChange(e, data, 'site', false)} name="site" placeholder="Site" search selection options={siteList} />
                      </Form.Field>
                      <Form.Field hidden={site !== 'Perth (All Locations)' && site.length > 0 ? false : true} error={siteVisitError} inline>
                        <label className="required">Are you visiting site this week?</label>
                        <Form.Radio label='Yes' value='Yes' checked={siteVisit === 'Yes'} onChange={(e, data) => this.handleChange(e, data, 'siteVisit', false)} />
                        <Form.Radio label='No' value='No' checked={siteVisit === 'No'} onChange={(e, data) => this.handleChange(e, data, 'siteVisit', false)} />
                      </Form.Field>
                  </div>
                  <div hidden={site === 'Perth (All Locations)' || siteVisit.length > 0 ? false : true}>
                    <div>
                        <Form.Field hidden={site === 'Perth (All Locations)' ? false : true} error={wopIdError}>
                          <label className="required">WOP ID (e.g WOPABC or W12345)</label>
                          <Input value={wopId} size='small' type="text" onChange={(e, data) => this.handleChange(e, data, 'wopId', false)} name="wopId" placeholder='WOP ID' />
                        </Form.Field>
                        <Form.Field hidden={site === 'Perth (All Locations)' ? true : false} error={siteIdError}>
                          <label className="required">Site ID</label>
                          <Input value={siteId} size='small' type="text" onChange={(e, data) => this.handleChange(e, data, 'siteId', false)} name="siteId" placeholder='Site ID' />
                        </Form.Field>
                        <Form.Field error={teamError}>
                        <label className="required">What team are you in?</label>
                        <Dropdown value={team} size='small' onChange={(e, data) => this.handleChange(e, data, 'team', false)} name="team" placeholder="Team" search selection options={teamList} />
                      </Form.Field>
                        <Form.Field error={employerError}>
                          <label className="required">Who is your Employer?</label>
                          <Input value={employer} size='small' type="text" onChange={(e, data) => this.handleChange(e, data, 'employer', false)} placeholder="Employer" name="employer" />
                        </Form.Field>
                        <Form.Field error={emailError}>
                          <label className="required">Email Address</label>
                          <Input value={email} size='small' type="text" onChange={(e, data) => this.handleChange(e, data, 'email', false)} name="email" placeholder='Email' />
                        </Form.Field>
                        <Form.Field error={phoneError}>
                          <label className="required">Contact Number</label>
                          <Input value={phone} size='small' type="text" onChange={(e, data) => this.handleChange(e, data, 'phone', false)} name="phone" placeholder='Contact Number' />
                        </Form.Field>
                    </div>
                    {/* <div>
                      <Form.Group widths='equal'>
                        <Form.Field error={mobilisationError}>
                          <label className="required">What date will you arrive at the Woodside Site?</label>
                          <input value={mobilisation} onChange={(e, data) => this.handleChange(e, data, 'mobilisation', true)} placeholder='Start Date' type="date" />
                        </Form.Field>
                        <Form.Field error={roleError}>
                          <label className="required">Role</label>
                          <Input value={role} size='small' type="text" onChange={(e, data) => this.handleChange(e, data, 'role', false)} name="role" placeholder='Role' />
                        </Form.Field>
                      </Form.Group>
                    </div> */}          
                    <div>
                      <Form.Group widths='equal'>
                      <Form.Field error={internationalTravelError} inline>
                        <label className="required">Have you travelled internationally in the last 14 days?</label>
                        <Form.Radio label='Yes' value='Yes' checked={internationalTravel === 'Yes'} onChange={(e, data) => this.handleChange(e, data, 'internationalTravel', false)} />
                        <Form.Radio label='No' value='No' checked={internationalTravel === 'No'} onChange={(e, data) => this.handleChange(e, data, 'internationalTravel', false)} />
                      </Form.Field>
                      </Form.Group>
                      <Form.Group widths='equal'>
                        <Form.Field>
                          <label> I declare I am Fit for Work based on the below criteria, in the last 14 days:</label>
                          <li>I am not suffering, or gave suffered, flu like symptoms.</li>
                          <li>I have not travelled to an international location. </li>
                          <li>I am not aware of having had exposure to an infected, or potentially infected, person. (Exposure would include face to face contact for at least 15 minutes or been in the same closed space for at least 2 hours. Someone who has recently travelled to a high risk country and has flu-like symptoms i.e. cough, fever, sore throat or shortness of breath. </li>
                          <li>I am not waiting on the return of results testing for COVID19. </li>
                          <Form.Checkbox checked={ffw} size='small' type="checkbox" onClick={() => this.setState({ ffw: !this.state.ffw })} />
                        </Form.Field>
                      </Form.Group>
                      <ReCAPTCHA
                        sitekey="6Ld_f-EUAAAAABa-vIIH7iIg_FHNQFTNdfMNkC-8"
                        onChange={this.onChange}
                      />
                      <br/>
                      <div className="btnSubmit">
                        <Button style={{ background: '#00354D', color: 'white' }} type='submit'>SUBMIT</Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </Grid>
            </Segment>
          </div>
        </div>
        <Dimmer active={!!this.state.loader} inverted>
          <Loader>Submitting Form...</Loader>
        </Dimmer>
      </div >
    );
  }
}
export default VisitorForm;
